import React from 'react';

export default class ButtonSecondary extends React.Component {
  render() {
    return (
      <button
        className={`${this.props.className &&
          this.props.className} btn btn-secondary`}
      >
        {this.props.children}
      </button>
    );
  }
}
