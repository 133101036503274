import React from 'react';

export default class Search extends React.Component {
  render() {
    return (
      <div className="SearchContainer">
        <input placeholder={this.props.placeholder} />
        <div className="SearchIconContainer">
          <span className="fa fa-search" />
        </div>
      </div>
    );
  }
}
