import React from 'react';

export default class SectionField extends React.Component {
  render() {
    return (
      <fieldset className="SectionField">
        <legend>{this.props.title}</legend>
        {this.props.children}
      </fieldset>
    );
  }
}
