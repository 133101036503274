import React from 'react';
import {withRouter} from 'react-router-dom';

class MenuItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isExpanded: false,
    };

    this.handleOnMenuItemclick = this.handleOnMenuItemclick.bind(this);
  }

  handleOnMenuItemclick() {
    console.log(this.props);
    if (this.props.linkTo) this.props.history.push(this.props.linkTo);
    else {
      this.setState((state, props) => ({
        isExpanded: !state.isExpanded,
      }));
    }
  }

  render() {
    return (
      <React.Fragment>
        <div
          onClick={this.handleOnMenuItemclick}
          className={`MenuItem${this.props.active ? ' active' : ''}${
            this.props.sub ? ' sub' : ''
          }`}
        >
          {String(this.props.icon).search('data:image') != -1 ? (
            <img src={this.props.icon} className="MenuItemIcon" />
          ) : (
            <span className={`MenuItemIcon fa fa-${this.props.icon}`} />
          )}
          <label
            className={`MenuItemLabel${this.props.labeled ? ' labeled' : ''}`}
          >
            {this.props.label}
            {React.Children.count(this.props.children) ? (
              <span
                className={`margin-r-x fa fa-${
                  this.state.isExpanded ? 'minus-circle' : 'plus-circle'
                } fa-xs`}
              />
            ) : (
              ''
            )}
          </label>
        </div>
        {this.state.isExpanded && this.props.children}
      </React.Fragment>
    );
  }
}

export default withRouter(MenuItem);
