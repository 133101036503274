import React from 'react';
import Table from '../components/Table';
import TableRow from '../components/TableRow';
import TableData from '../components/TableData';
import ButtonSecondary from '../components/Forms/ButtonSecondary';
import ButtonDanger from '../components/Forms/ButtonDanger';
import Page from '../containers/Page';

export default class AdminListPages extends React.Component {
  render() {
    return (
      <Page title="فهرست صفحات">
        <div className="col-xs-12">
          <Table fields={['عنوان', 'شناسه', 'عملیات']}>
            <TableRow>
              <TableData>ارتباط با ما</TableData>
              <TableData>about-us</TableData>
              <TableData>
                <ButtonSecondary>ویرایش</ButtonSecondary>
                <ButtonDanger>حذف</ButtonDanger>
              </TableData>
            </TableRow>
            <TableRow>
              <TableData>تماس با ما</TableData>
              <TableData>contact-us</TableData>
              <TableData>
                <ButtonSecondary>ویرایش</ButtonSecondary>
                <ButtonDanger>حذف</ButtonDanger>
              </TableData>
            </TableRow>
            <TableRow>
              <TableData>شرایط استفاده و قوانین</TableData>
              <TableData>tos</TableData>
              <TableData>
                <ButtonSecondary>ویرایش</ButtonSecondary>
                <ButtonDanger>حذف</ButtonDanger>
              </TableData>
            </TableRow>
          </Table>
        </div>
      </Page>
    );
  }
}
