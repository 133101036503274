import React from 'react';

export default class ButtonPrimary extends React.Component {
  render() {
    return (
      <button
        className={`${this.props.className &&
          this.props.className} btn btn-primary`}
      >
        {this.props.children}
      </button>
    );
  }
}
