import React from 'react';

export default class SideMenuContainer extends React.Component {
  render() {
    return (
      <div
        className={`SideMenuContainer${
          !this.props.toggled ? ' hidden-lt-lg' : ''
        }`}
      >
        {this.props.children}
      </div>
    );
  }
}
