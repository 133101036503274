import React from 'react';
import InputFormField from '../components/Forms/InputFormField';
import Page from '../containers/Page';
import SectionField from '../components/SectionField';
import FroalaEditor from 'react-froala-wysiwyg';
import SelectFormField from '../components/Forms/SelectFormField';
import ButtonDanger from '../components/Forms/ButtonDanger';
import ButtonSecondary from '../components/Forms/ButtonSecondary';
import ButtonPrimary from '../components/Forms/ButtonPrimary';

export default class AdminSettings extends React.Component {
  render() {
    return (
      <Page title="تنظیمات">
        <div className="col-xs-12">
          <SectionField title="تنظیمات سئو">
            <InputFormField label="عنوان سایت" />
            <div className="FormInputContainer margin-b-5x">
              <label className="FormInputLabel">توضیحات سئو</label>
              <FroalaEditor
                tag="textarea"
                config={{
                  height: 100,
                  iframe: true,
                  direction: 'rtl',
                  placeholderText: 'مقدار داده متا را اینجا وارد نمایید',
                  language: 'fa',
                  toolbarButtons: [],
                }}
              />
            </div>
            <InputFormField label="کلمات کلیدی" />
          </SectionField>
          <SectionField title="اطلاعات تماس">
            <InputFormField label="ایمیل" type="email" />
            <InputFormField label="شماره تماس" type="tel" />
          </SectionField>
          <SectionField title="تنظیمات عمومی">
            <SelectFormField
              label="وضیعیت سایت"
              options={[
                {title: 'فعال', value: 'active'},
                {title: 'غیر فعال', value: 'inactive'},
              ]}
            />
            <InputFormField label="تعداد مدخل های صفحه اصلی" />
          </SectionField>
          <SectionField title="پیوند های پابرگ">
            <div className="row">
              <div className="col-xs-12">
                <div className="FormInputContainer MultiFormInputContainer margin-b-5x">
                  <input
                    className="FormInput MultiFormInput"
                    placeholder="عنوان پیوند"
                  />
                  <input
                    className="FormInput MultiFormInput"
                    placeholder="آدرس پیوند"
                  />
                </div>
                <div className="FormInputContainer margin-b-6x">
                  <ButtonPrimary>افزودن</ButtonPrimary>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12">
                <div className="FormInputContainer MultiFormInputContainer margin-b-xx">
                  <input
                    className="FormInput MultiFormInput"
                    value="قوانین"
                    disabled
                  />
                  <input
                    className="FormInput MultiFormInput"
                    value="/rules"
                    disabled
                  />
                  <ButtonSecondary>ویرایش</ButtonSecondary>
                  <ButtonDanger>حذف</ButtonDanger>
                </div>
                <div className="FormInputContainer MultiFormInputContainer margin-b-xx">
                  <input
                    className="FormInput MultiFormInput"
                    value="تماس با ما"
                    disabled
                  />
                  <input
                    className="FormInput MultiFormInput"
                    value="/contact-us"
                    disabled
                  />
                  <ButtonSecondary>ویرایش</ButtonSecondary>
                  <ButtonDanger>حذف</ButtonDanger>
                </div>
              </div>
            </div>
          </SectionField>
        </div>
      </Page>
    );
  }
}
