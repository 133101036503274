import React from 'react';

export default class InputFormField extends React.Component {
  render() {
    return (
      <div className="FormInputContainer">
        {this.props.label && (
          <label className="FormInputLabel">{this.props.label}</label>
        )}
        <input className="FormInput" type={this.props.type || 'text'} />
      </div>
    );
  }
}
