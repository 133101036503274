import React from 'react';

export default class SelectFormField extends React.Component {
  render() {
    return (
      <div className="FormInputContainer">
        {this.props.label && (
          <label className="FormInputLabel">{this.props.label}</label>
        )}
        <select className="FormInput">
          {this.props.options.map((opt) => (
            <option value={opt.value}>{opt.title}</option>
          ))}
        </select>
      </div>
    );
  }
}
