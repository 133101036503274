import React from 'react';

import MenuItem from '../components/MenuItem';
import SideMenuContainer from '../containers/SideMenuContainer';
import MainMenuContainer from '../containers/MainMenuContainer';
import MainSectionContainer from '../containers/MainSectionContainer';

import {BrowserRouter as Router} from 'react-router-dom';

import ComplexMenuItem from '../components/ComplexMenuItem';

export default class Layout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMenuToggled: false,
      bioText: '',
    };

    this.handleMenuToggleClick = this.handleMenuToggleClick.bind(this);
  }

  handleMenuToggleClick() {
    this.setState((state, props) => ({
      isMenuToggled: !state.isMenuToggled,
    }));
  }

  render() {
    return (
      <Router>
        <div>
          <SideMenuContainer toggled={this.state.isMenuToggled}>
            {this.props.sideMenuItems.map((item, index) => (
              <MenuItem
                key={index}
                label={item.title}
                icon={item.icon}
                linkTo={item.linkTo}
              >
                {item.subMenuItems &&
                  item.subMenuItems.map((subMenuItem, subIndex) => (
                    <MenuItem
                      key={subIndex}
                      label={subMenuItem.title}
                      icon={subMenuItem.icon}
                      linkTo={subMenuItem.linkTo}
                      sub
                    />
                  ))}
              </MenuItem>
            ))}
            ;
          </SideMenuContainer>
          <MainMenuContainer
            brand={this.props.brand}
            onMenuToggleClick={this.handleMenuToggleClick}
          >
            {this.props.mainMenuItems.map((item, index) =>
              item.complex ? (
                <ComplexMenuItem
                  key={index}
                  icon={item.icon}
                  label={item.title}
                  labeled={item.labeled}
                >
                  <item.complexComponent />
                </ComplexMenuItem>
              ) : (
                <MenuItem
                  key={index}
                  label={item.title}
                  icon={item.icon}
                  labeled={item.labeled}
                  linkTo={item.linkTo}
                />
              )
            )}
          </MainMenuContainer>
          <MainSectionContainer>{this.props.routes}</MainSectionContainer>
        </div>
      </Router>
    );
  }
}
