import React from 'react';

export default class UserEntity extends React.Component {
  render() {
    return (
      <div className="UserEntity">
        <div className="UserEntityAvatar">
          {String(this.props.avatar).search('data:image') !== -1 ? (
            <img src={this.props.avatar} />
          ) : (
            <span className={`MenuItemIcon fa fa-${this.props.avatar}`} />
          )}
        </div>
        <div className="UserEntityInfo">
          <label className="UserEntityInfoName">{this.props.name}</label>
          <span className="UserEntityInfoLinks">{this.props.children}</span>
        </div>
      </div>
    );
  }
}
