import React from 'react';

import GalleryItem01 from '../assets/images/wallpapers/wall01.jpg';
import GalleryItem02 from '../assets/images/wallpapers/wall02.jpg';
import GalleryItem03 from '../assets/images/wallpapers/wall03.jpg';

import FroalaEditor from 'react-froala-wysiwyg';
import InputFormField from '../components/Forms/InputFormField';
import ButtonPrimary from '../components/Forms/ButtonPrimary';
import ButtonSecondary from '../components/Forms/ButtonSecondary';
import ButtonDanger from '../components/Forms/ButtonDanger';
import SectionField from '../components/SectionField';
import SelectFormField from '../components/Forms/SelectFormField';
import Quote from '../components/Quote';
import Label from '../components/Label';
import Page from '../containers/Page';
import ContentGallery from '../components/ContentGallery';

export default class AdminNewPerson extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bioText: '',
    };

    this.handleBioTextChange = this.handleBioTextChange.bind(this);
  }

  handleBioTextChange(model) {
    console.log(model);
    this.setState({
      bioText: model,
    });
  }

  render() {
    return (
      <Page title="شخصیت جدید">
        <div className="col-lg-3 col-md-12">
          <SectionField title="انتشار">
            <div className="row">
              <div className="col-lg-12 col-xs-6 pull-right">
                <SelectFormField
                  label="وضعیت انتشار"
                  options={[
                    {title: 'عمومی', value: 'public'},
                    {title: 'پیشنویس', value: 'draft'},
                  ]}
                />
                <InputFormField type="date" label="تاریخ ویرایش" />
                <ButtonPrimary>ویرایش تغییرات</ButtonPrimary>
              </div>
            </div>
          </SectionField>
          <SectionField title="برچسب محتوا">
            <div className="row">
              <div className="col-xs-12">
                <InputFormField label="عنوان برچسب" />
                <ButtonPrimary className="margin-b-5x">افزودن</ButtonPrimary>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12">
                <Label theme="light" className="margin-r-x">
                  علی تقی <span className="fa fa-minus-circle" />
                </Label>
                <Label theme="light" className="margin-r-x">
                  ببر آسیا <span className="fa fa-minus-circle" />
                </Label>
                <Label theme="light" className="margin-r-x">
                  خاور میانه <span className="fa fa-minus-circle" />
                </Label>
              </div>
            </div>
          </SectionField>
        </div>
        <div className="col-lg-9 col-md-12">
          <SectionField title="مشخصات اصلی">
            <div className="row">
              <div className="col-lg-4 col-sm-4">
                <InputFormField label="پیشوند" />
              </div>
              <div className="col-lg-4 col-sm-4">
                <InputFormField label="نام خانوادگی" />
              </div>
              <div className="col-lg-4 col-sm-4">
                <InputFormField label="نام" />
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 pull-right">
                <SelectFormField
                  label="جنسیت"
                  options={[
                    {title: 'مذکر', value: 'male'},
                    {title: 'مونث', value: 'female'},
                  ]}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 pull-right">
                <InputFormField type="date" title="تاریخ تولد" />
              </div>
              <div className="col-md-4 pull-right">
                <InputFormField type="date" title="تاریخ وفات" />
              </div>
            </div>
          </SectionField>

          <SectionField title="بیوگرافی">
            <div className="row">
              <div className="col-xs-12">
                <FroalaEditor
                  tag="textarea"
                  model={this.state.config}
                  onModelChange={this.handleBioTextChange}
                  config={{
                    height: 250,
                    iframe: true,
                    direction: 'rtl',
                    placeholderText: 'متن بیوگرافی را اینجا وارد نمایید',
                    language: 'fa',
                    toolbarButtons: [
                      'bold',
                      'italic',
                      'underline',
                      'strikeThrough',
                      '|',
                      'subscript',
                      'superscript',
                      '|',
                      'outdent',
                      'indent',
                      'align',
                      '|',
                      'insertLink',
                      'insertImage',
                      'insertTable',
                      'quote',
                      '|',
                      'paragraphFormat',
                      'clearFormatting',
                      '|',
                      'html',
                      'fullscreen',
                    ],
                  }}
                />
              </div>
            </div>
          </SectionField>
          <SectionField title="گفتاورد ها">
            <div className="row">
              <div className="col-md-4 pull-right">
                <InputFormField label="نقل شده توسط" />
              </div>
              <div className="col-md-4 pull-right">
                <InputFormField label="نقل شده در مورد" />
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12">
                <div className="FormInputContainer margin-b-5x">
                  <FroalaEditor
                    tag="textarea"
                    config={{
                      height: 100,
                      iframe: true,
                      direction: 'rtl',
                      placeholderText: 'متن نقل قول را اینجا وارد نمایید',
                      language: 'fa',
                      toolbarButtons: [],
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12">
                <ButtonPrimary className="margin-b-5x">افزودن</ButtonPrimary>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12">
                <Quote
                  header={
                    <span>
                      <Label>توسط</Label>
                      <Label theme="light" className="margin-r-x margin-l-x">
                        محمد رضایی
                      </Label>
                      <Label>در مورد</Label>
                      <Label theme="light" className="margin-r-x margin-l-x">
                        حمید حمیدی
                      </Label>
                    </span>
                  }
                  text={
                    <span>
                      <p>
                        لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت
                        چاپ و با استفاده از طراحان گرافیک است. چاپگرها و متون
                        بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و
                        برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای متنوع با
                        هدف بهبود ابزارهای کاربردی می باشد. کتابهای زیادی در شصت
                        و سه درصد گذشته، حال و آینده شناخت فراوان جامعه و
                        متخصصان را می طلبد تا با نرم افزارها شناخت بیشتری را
                        برای طراحان رایانه ای علی الخصوص طراحان خلاقی و فرهنگ
                        پیشرو در زبان فارسی ایجاد کرد.
                      </p>
                    </span>
                  }
                  footer={
                    <span>
                      <ButtonSecondary>ویرایش</ButtonSecondary>
                      <ButtonDanger>حذف</ButtonDanger>
                    </span>
                  }
                />
              </div>
            </div>
          </SectionField>
          <SectionField title="خصیصه ها">
            <div className="row">
              <div className="col-xs-12">
                <div className="FormInputContainer MultiFormInputContainer margin-b-5x">
                  <input
                    className="FormInput MultiFormInput"
                    placeholder="عنوان داده"
                  />
                  <input
                    className="FormInput MultiFormInput"
                    placeholder="مقدار داده"
                  />
                </div>
                <div className="FormInputContainer margin-b-6x">
                  <ButtonPrimary>افزودن</ButtonPrimary>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12">
                <div className="FormInputContainer MultiFormInputContainer margin-b-xx">
                  <input
                    className="FormInput MultiFormInput"
                    placeholder="عنوان داده"
                    value="عملیات های حضور یافته"
                    disabled
                  />
                  <input
                    className="FormInput MultiFormInput"
                    placeholder="مقدار مقدار"
                    value="بیت المقدس"
                    disabled
                  />
                  <ButtonSecondary>ویرایش</ButtonSecondary>
                  <ButtonDanger>حذف</ButtonDanger>
                </div>
                <div className="FormInputContainer MultiFormInputContainer margin-b-xx">
                  <input
                    className="FormInput MultiFormInput"
                    placeholder="عنوان داده"
                    value="عملیات های حضور یافته"
                    disabled
                  />
                  <input
                    className="FormInput MultiFormInput"
                    placeholder="مقدار مقدار"
                    value="ثامن الئمه"
                    disabled
                  />
                  <ButtonSecondary>ویرایش</ButtonSecondary>
                  <ButtonDanger>حذف</ButtonDanger>
                </div>
                <div className="FormInputContainer MultiFormInputContainer margin-b-xx">
                  <input
                    className="FormInput MultiFormInput"
                    placeholder="عنوان داده"
                    value="همسران"
                    disabled
                  />
                  <input
                    className="FormInput MultiFormInput"
                    placeholder="مقدار مقدار"
                    value="سارا رجب"
                    disabled
                  />
                  <ButtonSecondary>ویرایش</ButtonSecondary>
                  <ButtonDanger>حذف</ButtonDanger>
                </div>
              </div>
            </div>
          </SectionField>
          <SectionField title="گالری">
            <ButtonPrimary className="margin-b-5x">
              افزودن تصویر جدید
            </ButtonPrimary>
            <ContentGallery
              items={[
                {src: GalleryItem01, isCover: true},
                {src: GalleryItem02},
                {src: GalleryItem03},
              ]}
            />
          </SectionField>
        </div>
      </Page>
    );
  }
}
