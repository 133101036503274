import React from 'react';

export default class Label extends React.Component {
  render() {
    return (
      <label
        className={`Labeled ${
          this.props.className ? this.props.className : ''
        }${
          this.props.theme
            ? ` Labeled${this.props.theme.charAt(0).toUpperCase() +
                this.props.theme.slice(1)}`
            : ''
        }`}
      >
        {this.props.children}
      </label>
    );
  }
}
