import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import './assets/css/main.css';

import $ from 'jquery';
import 'font-awesome/css/font-awesome.css';

import 'froala-editor/js/froala_editor.pkgd.min.js';
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/js/languages/fa.js';

global.$ = $;
window.$ = $;

ReactDOM.render(<App />, document.getElementById('root'));
