import React from 'react';

export default class AdminListPages extends React.Component {
  render() {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-xs-12">
            <h5 className="margin-6x">{this.props.title}</h5>
          </div>
        </div>
        <div className="row">{this.props.children}</div>
      </div>
    );
  }
}
