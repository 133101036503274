import React from 'react';
import ButtonSecondary from './Forms/ButtonSecondary';
import ButtonDanger from './Forms/ButtonDanger';

export default class ContentGallery extends React.Component {
  render() {
    return (
      <div className="Gallery">
        {this.props.items.map((item) => (
          <div className="GalleryItemContainer">
            <img src={item.src} className="GalleryItemImage" />
            <div className="GalleryItemToolbar">
              <ButtonSecondary>
                {item.isCover ? 'تصویر کاور' : 'تصویر استاندارد'}
              </ButtonSecondary>
              <ButtonDanger>حذف تصویر</ButtonDanger>
            </div>
          </div>
        ))}
      </div>
    );
  }
}
