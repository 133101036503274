import React from 'react';
import Table from '../components/Table';
import TableRow from '../components/TableRow';
import TableData from '../components/TableData';
import ButtonSecondary from '../components/Forms/ButtonSecondary';
import ButtonDanger from '../components/Forms/ButtonDanger';
import Page from '../containers/Page';

export default class AdminListTags extends React.Component {
  render() {
    return (
      <Page title="فهرست برچسب ها">
        <div className="col-xs-12">
          <Table fields={['عنوان', 'شناسه', 'نوع', 'عملیات']}>
            <TableRow>
              <TableData>همسران</TableData>
              <TableData>spouses</TableData>
              <TableData>ریز داده</TableData>
              <TableData>
                <ButtonSecondary>ویرایش</ButtonSecondary>
                <ButtonDanger>حذف</ButtonDanger>
              </TableData>
            </TableRow>
            <TableRow>
              <TableData>فرزندان</TableData>
              <TableData>children</TableData>
              <TableData>ریز داده</TableData>
              <TableData>
                <ButtonSecondary>ویرایش</ButtonSecondary>
                <ButtonDanger>حذف</ButtonDanger>
              </TableData>
            </TableRow>
            <TableRow>
              <TableData>علی ضیا</TableData>
              <TableData>علی-ضیا</TableData>
              <TableData>برچسب محتوا</TableData>
              <TableData>
                <ButtonSecondary>ویرایش</ButtonSecondary>
                <ButtonDanger>حذف</ButtonDanger>
              </TableData>
            </TableRow>
            <TableRow>
              <TableData>عملیات ها حضور یافته</TableData>
              <TableData>operations</TableData>
              <TableData>ریز داده</TableData>
              <TableData>
                <ButtonSecondary>ویرایش</ButtonSecondary>
                <ButtonDanger>حذف</ButtonDanger>
              </TableData>
            </TableRow>
            <TableRow>
              <TableData>بیت المقدس</TableData>
              <TableData>بیت-المقدس</TableData>
              <TableData>مقدار ریز داده</TableData>
              <TableData>
                <ButtonSecondary>ویرایش</ButtonSecondary>
                <ButtonDanger>حذف</ButtonDanger>
              </TableData>
            </TableRow>
          </Table>
        </div>
      </Page>
    );
  }
}
