import React from 'react';
import UserEntity from '../components/UserEntity';

export default class MainMenuUserEntity extends React.Component {
  render() {
    return (
      <div className="flex-row flex-caxis-center margin-r-xx">
        <UserEntity
          name="احمدرضا حسینی مدقق"
          avatar="user"
          className="margin-r-x"
        />
      </div>
    );
  }
}
