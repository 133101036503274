import React from 'react';

import BrandImage from './assets/images/BrandMain.png';

import {Route} from 'react-router-dom';

import AdminNewPerson from './pages/AdminNewPerson';
import AdminListPersons from './pages/AdminListPersons';
import AdminListTags from './pages/AdminListTags';
import AdminIndex from './pages/AdminIndex';
import Layout from './containers/Layout';

import MainMenuSearch from './containers/MainMenuSearch';
import MainMenuUserEntity from './containers/MainMenuUserEntity';
import AdminNewTag from './pages/AdminNewTag';
import AdminListPages from './pages/AdminListPages';
import AdminNewPage from './pages/AdminNewPage';
import AdminSettings from './pages/AdminSettings';
import AdminComments from './pages/AdminComments';

export default class App extends React.Component {
  render() {
    return (
      <Layout
        brand={BrandImage}
        routes={[
          <Route exact path="/" component={AdminIndex} />,
          <Route path="/content/persons/new" component={AdminNewPerson} />,
          <Route exact path="/content/persons/" component={AdminListPersons} />,
          <Route exact path="/content/tags/" component={AdminListTags} />,
          <Route exact path="/content/tags/new" component={AdminNewTag} />,
          <Route exact path="/content/pages" component={AdminListPages} />,
          <Route exact path="/content/pages/new" component={AdminNewPage} />,
          <Route exact path="/settings" component={AdminSettings} />,
          <Route exact path="/comments" component={AdminComments} />,
        ]}
        mainMenuItems={[
          {
            complex: true,
            complexComponent: MainMenuUserEntity,
            title: 'حساب کاربری',
            icon: 'user',
          },
          {
            title: 'اعلان ها',
            icon: 'bell',
            labeled: true,
            linkTo: '/notifications',
          },
          {
            title: 'پیام ها',
            icon: 'envelope',
            labeled: true,
            linkTo: '/messages',
          },
          {
            complex: true,
            complexComponent: MainMenuSearch,
            title: 'جستجو',
            icon: 'search',
          },
        ]}
        sideMenuItems={[
          {
            title: 'پیشخوان',
            icon: 'tachometer',
            subMenuItems: [
              {title: 'پیشخوان شما', linkTo: '/'},
              {title: 'تغییرات نسخه جدید', linkTo: '/release'},
            ],
          },
          {
            title: 'شخصیت ها',
            icon: 'user-plus',
            subMenuItems: [
              {title: 'شخصیت جدید', linkTo: '/content/persons/new'},
              {title: 'فهرست شخصیت ها', linkTo: '/content/persons'},
            ],
          },
          {
            title: 'برچسب ها',
            icon: 'tag',
            subMenuItems: [
              {title: 'برچسب جدید', linkTo: '/content/tags/new'},
              {title: 'فهرست برچسب ها', linkTo: '/content/tags'},
            ],
          },
          {
            title: 'صفحات',
            icon: 'columns',
            subMenuItems: [
              {title: 'صفحه جدید', linkTo: '/content/pages/new'},
              {title: 'فهرست صفحات', linkTo: '/content/pages'},
            ],
          },
          {
            title: 'مدیریت فایل ها',
            icon: 'file',
            linkTo: '/filemanager',
          },
          {
            title: 'نظرات',
            icon: 'comments',
            linkTo: '/comments',
          },
          {
            title: 'تنظیمات',
            icon: 'cog',
            linkTo: '/settings',
          },
        ]}
      />
    );
  }
}
