import React from 'react';
import TableData from './TableData';
import TableRow from './TableRow';

export default class Table extends React.Component {
  render() {
    return (
      <table className="Table">
        {this.props.fields.length && (
          <thead>
            <TableRow>
              {this.props.fields.map((fieldTitle) => (
                <TableData>
                  <label>{fieldTitle}</label>
                </TableData>
              ))}
            </TableRow>
          </thead>
        )}
        {this.props.children}
      </table>
    );
  }
}
