import React from 'react';
import Page from '../containers/Page';
import SectionField from '../components/SectionField';
import ButtonDanger from '../components/Forms/ButtonDanger';
import ButtonSuccess from '../components/Forms/ButtonSuccess';
import Quote from '../components/Quote';
import Label from '../components/Label';

export default class AdminComments extends React.Component {
  render() {
    return (
      <Page title="تنظیمات">
        <div className="col-xs-12">
          <SectionField title="دیدگاه های ثبت شده">
            <Quote
              header={
                <span>
                  <Label>نوشته شده توسط</Label>
                  <Label theme="light">سید علی محمدی</Label>
                </span>
              }
              text={
                <p>
                  لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با
                  استفاده از طراحان گرافیک است. چاپگرها و متون بلکه روزنامه و
                  مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی
                  تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود ابزارهای
                  کاربردی می باشد. کتابهای زیادی در شصت و سه درصد گذشته، حال و
                  آینده شناخت فراوان جامعه و متخصصان را می طلبد تا با نرم
                  افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص
                  طراحان خلاقی و فرهنگ پیشرو در زبان فارسی ایجاد کرد.
                </p>
              }
              footer={<Label theme="success">تایید شده</Label>}
            />
            <Quote
              header={
                <span>
                  <Label>نوشته شده توسط</Label>
                  <Label theme="light">سید علی محمدی</Label>
                </span>
              }
              text={
                <p>
                  لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با
                  استفاده از طراحان گرافیک است. چاپگرها و متون بلکه روزنامه و
                  مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی
                  تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود ابزارهای
                  کاربردی می باشد. کتابهای زیادی در شصت و سه درصد گذشته، حال و
                  آینده شناخت فراوان جامعه و متخصصان را می طلبد تا با نرم
                  افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص
                  طراحان خلاقی و فرهنگ پیشرو در زبان فارسی ایجاد کرد.
                </p>
              }
              footer={<Label theme="danger">تایید نشده</Label>}
            />
            <Quote
              header={
                <span>
                  <Label>نوشته شده توسط</Label>
                  <Label theme="light">سید علی محمدی</Label>
                </span>
              }
              text={
                <p>
                  لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با
                  استفاده از طراحان گرافیک است. چاپگرها و متون بلکه روزنامه و
                  مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی
                  تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود ابزارهای
                  کاربردی می باشد. کتابهای زیادی در شصت و سه درصد گذشته، حال و
                  آینده شناخت فراوان جامعه و متخصصان را می طلبد تا با نرم
                  افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص
                  طراحان خلاقی و فرهنگ پیشرو در زبان فارسی ایجاد کرد.
                </p>
              }
              footer={
                <span>
                  <Label theme="warning" className="margin-l-x">
                    در انتظار تایید
                  </Label>
                  <ButtonSuccess className="margin-l-x">تایید</ButtonSuccess>
                  <ButtonDanger>عدم تایید</ButtonDanger>
                </span>
              }
            />
          </SectionField>
        </div>
      </Page>
    );
  }
}
