import React from 'react';
import Table from '../components/Table';
import TableRow from '../components/TableRow';
import TableData from '../components/TableData';
import ButtonSecondary from '../components/Forms/ButtonSecondary';
import ButtonDanger from '../components/Forms/ButtonDanger';
import Page from '../containers/Page';

export default class AdminListPersons extends React.Component {
  render() {
    return (
      <Page title="فهرست شخصیت ها">
        <div className="col-xs-12">
          <Table fields={['نام', 'عملیات']}>
            <TableRow>
              <TableData>سید علی ضیا</TableData>
              <TableData>
                <ButtonSecondary>ویرایش</ButtonSecondary>
                <ButtonDanger>حذف</ButtonDanger>
              </TableData>
            </TableRow>
          </Table>
        </div>
      </Page>
    );
  }
}
