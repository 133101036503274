import React from 'react';

export default class Quote extends React.Component {
  render() {
    return (
      <div className="Quote">
        <div className="QuoteHeader">{this.props.header}</div>
        <div className="QuoteText">{this.props.text}</div>
        <div className="QuoteFooter">{this.props.footer}</div>
      </div>
    );
  }
}
