import React from 'react';
import FroalaEditor from 'react-froala-wysiwyg';
import SectionField from '../components/SectionField';
import InputFormField from '../components/Forms/InputFormField';
import ButtonPrimary from '../components/Forms/ButtonPrimary';
import Page from '../containers/Page';

export default class AdminNewPage extends React.Component {
  render() {
    return (
      <Page title="صفحه جدید">
        <div className="col-xs-12">
          <SectionField title="اطلاعات اصلی">
            <InputFormField label="عنوان صفحه" />
            <InputFormField label="شناسه صفحه" />
          </SectionField>
          <SectionField title="متن صفحه">
            <div className="FormInputContainer margin-b-5x">
              <FroalaEditor
                tag="textarea"
                config={{
                  height: 250,
                  iframe: true,
                  direction: 'rtl',
                  placeholderText: 'متن صفحه را اینجا وارد نمایید',
                  language: 'fa',
                  toolbarButtons: [
                    'bold',
                    'italic',
                    'underline',
                    'strikeThrough',
                    '|',
                    'subscript',
                    'superscript',
                    '|',
                    'outdent',
                    'indent',
                    'align',
                    '|',
                    'insertLink',
                    'insertImage',
                    'insertTable',
                    'quote',
                    '|',
                    'paragraphFormat',
                    'clearFormatting',
                    '|',
                    'html',
                    'fullscreen',
                  ],
                }}
              />
            </div>
          </SectionField>
          <ButtonPrimary>ذخیره تغییرات</ButtonPrimary>
        </div>
      </Page>
    );
  }
}
