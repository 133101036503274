import React from 'react';
import SectionField from '../components/SectionField';
import Page from '../containers/Page';

export default class AdminIndex extends React.Component {
  render() {
    return (
      <Page title="خوش آمدید!">
        <div className="col-xs-12">
          <SectionField title="به پنل کاربری آدمینیتو خوش آمدید!">
            <p>
              پنل کاربری آدمینیتو با استفاده از بروزترین ابزار های بهینه توسعه
              رابط های کاربری تحت وب و به صورت کنشگرا جهت استفاده در گوشی های
              تلفن همراه و سایر دستگاه ها طراحی شده است.
            </p>
            <p>
              این برنامه از مجموعه ماژول های کاربردی تشکیل شده است که همگی با
              استفاده از کتابخانه ReactJS از طریق علامتگذاری JSX تبدیل به قالب
              های بصری شده اند. در اینجا تعدادی از ویژگی های این پنل را بررسی می
              کنیم.
            </p>
          </SectionField>
          <SectionField title="ویژگی های آدمینیتو">
            <h5 className="margin-b-x">
              استفاده از ویرایشگر متن حرفه ای Froala
            </h5>
            <p>
              شما می توانید از پکیج رسمی ویرایشگر Froala جهت پیاده سازی مکانیسم
              های مربوط به مدیریت محتوای سامانه استفاده نمایید. این ویرایشگر
              امکان فارسی و شخصی سازی حرفه ای را برای شما فراهم می کند.
            </p>
            <hr className="" />
            <h5 className="margin-b-x">Font Awesome</h5>
            <p>
              در زمینه آدمینیتو میتوانید براحتی از کلاس های گرفیکی رسمی Font
              Awesome استفاده نمایید. این ابزار در تمام قسمت های برنامه قابل
              دسترس می باشد.
            </p>
            <hr className="" />
            <h5 className="margin-b-x">گالری محتوا</h5>
            <p>
              در صورتی که محتوای مورد نظر شما امکان دسترسی به تصاویر آنلاین را
              برای کاربران فراهم می کند، می توانید از گالری طراحی شده در
              آدمینیتو جهت پیاده سازی مکانیسم های مربوطه استفاده نمایید.
            </p>
            <hr className="" />
            <h5 className="margin-b-x">فرم های اختصاصی و فیلد ست ها</h5>
            <p>
              در آدمینیتو می توانید از کلاس های از قبل طراحی شده جهت پیاده سازی
              فیلد های ورودی در فرم های خود استفاده نمایید. انواع فیلد های ورودی
              به صورت فارسی در سیستم تعبیه شده اند. همچنین در صورتی که نیاز به
              دسته بندی فیلد های مختلف دارید می توانید از ابزار Fildset آدمینیتو
              استافده نمایید.
            </p>
            <hr className="" />
            <h5 className="margin-b-x">فیلد های auto-Complete</h5>
            <p>
              در صورت تمایل به استفاده از روش های جدید تعامل با کاربر نهایی می
              توانید از ابزار ورودی های auto-complete در فرم های خود جهت تسریع
              فرایند دسترسی به داده ها استفاده نمایید.
            </p>
            <hr className="" />
            <h5 className="margin-b-x">جدول فهرست محتوا</h5>
            <p>
              یکی از نیازمندی های بدیهی در پیاده سازی سیستم های مدیریت محتوا،
              ارایه فهرست محتوای ثبت شده توسط کاربر می باشد. این امکان در
              آدمینیتو با استفاده از جداول خوش ساخت مخصوص به این موضوع پیاده
              سازی شده است.
            </p>
            <hr className="" />
            <h5 className="margin-b-x">منو سریع السیر</h5>
            <p>
              منو های سریع السیر آدمینیتو امکان دسترسی سریع به بخش های مختلف پنل
              کاربری را مهیا می کنند. آنچه باعث نامگذاری این منو ها بنام سریع
              السیر شده است، شیوه استفاده از این منو ها در هنگام توسعه سامانه و
              همچنین قابلیت کنشگرا بودن آنها در دستگاه های مختلف است. ما از آیتم
              های پیچیده منو ها (Complex Menu Items) جهت پیاده سازی نما های
              متفاوت از آیتم ها در دستگاه های مختلف استفاده می کنیم.
            </p>
            <hr className="" />
            <h5 className="margin-b-x">مدیریت فایل</h5>
            <p>
              در آدمینیتو مدیریت فایل ها از همیشه آسان تر است. تنها کافیست
              EndPoint های اختصاصی خود را جهت هماهنگی ماژول مدیریت فایل ما با
              سرور های خود تنظیم نمایید.
            </p>
            <hr className="" />
            <h5 className="margin-b-x">رابط کار با API</h5>
            <p>
              آدمینیتو این امکان را فراهم کرده است، تا بتوانید از Endpoint های
              API اختصاصی خود به صورت یکپارچه استفاده نمایید.
            </p>
            <hr className="" />
            <h5 className="margin-b-x">ابزار های از پیش طراحی شده</h5>
            <p>
              علاوه بر ماژول های فوق که پایه و اساس پنل های کاربری را تشکیل می
              دهند، آدمینیتو ابزار های حرفه ای تری نظیر جستجوگر پنل و سیستم ثبت
              اعلان را برای شما مهیا کرده است.{' '}
            </p>
          </SectionField>
        </div>
      </Page>
    );
  }
}
