import React from 'react';
import Search from '../components/Search';

export default class MainMenuSearch extends React.Component {
  render() {
    return (
      <div className="flex-row flex-caxis-center margin-l-xx">
        <Search placeholder="جستجو در پنل کاربری" />
      </div>
    );
  }
}
