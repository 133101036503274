import React from 'react';

export default class TableData extends React.Component {
  render() {
    return (
      <td className="TableData" colSpan={this.props.colSpan || 1}>
        {this.props.children}
      </td>
    );
  }
}
