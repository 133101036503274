import React from 'react';

import FroalaEditor from 'react-froala-wysiwyg';
import SectionField from '../components/SectionField';
import ButtonPrimary from '../components/Forms/ButtonPrimary';
import SelectFormField from '../components/Forms/SelectFormField';
import InputFormField from '../components/Forms/InputFormField';

export default class AdminNewTag extends React.Component {
  render() {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-xs-12">
            <h5 className="margin-b-6x">برچسب جدید</h5>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <SectionField title="اطلاعات اصلی">
              <InputFormField label="عنوان برچسب" />
              <InputFormField label="شناسه برچسب" />
              <SelectFormField
                label="نوع برچسب"
                options={[
                  {title: 'مقدار خصیصه', value: 'traitValue'},
                  {title: 'عنوان خصیصه', value: 'traitTitle'},
                ]}
              />
            </SectionField>
            <SectionField title="داده های متا">
              <div className="FormInputContainer margin-b-5x">
                <FroalaEditor
                  tag="textarea"
                  config={{
                    height: 100,
                    iframe: true,
                    direction: 'rtl',
                    placeholderText: 'مقدار داده متا را اینجا وارد نمایید',
                    language: 'fa',
                    toolbarButtons: [],
                  }}
                />
              </div>
            </SectionField>
            <ButtonPrimary>ذخیره تغییرات</ButtonPrimary>
          </div>
        </div>
      </div>
    );
  }
}
