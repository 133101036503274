import React from 'react';

export default class ComplexMenuItem extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div className={`MenuItem hidden-gt-md`}>
          {String(this.props.icon).search('data:image') !== -1 ? (
            <img src={this.props.icon} className="MenuItemIcon" />
          ) : (
            <span className={`MenuItemIcon fa fa-${this.props.icon}`} />
          )}
          <label
            className={`MenuItemLabel${this.props.labeled ? ' labeled' : ''}`}
          >
            {this.props.label}
          </label>
        </div>
        {this.props.children && (
          <div className="hidden-lt-lg">{this.props.children}</div>
        )}
      </React.Fragment>
    );
  }
}
