import React from 'react';
import {withRouter} from 'react-router-dom';

class MainMenuContainer extends React.Component {
  constructor(props) {
    super(props);

    this.handleOnBrandClick = this.handleOnBrandClick.bind(this);
  }

  handleOnBrandClick() {
    console.log(this.props);
    this.props.history.push('/');
  }

  render() {
    return (
      <React.Fragment>
        <div className="MainMenuContainer hidden-gt-md top">
          <span
            className="MainMenuContainerMenuIcon fa fa-bars"
            onClick={this.props.onMenuToggleClick}
          />
          <img className="MainMenuContainerBrand" src={this.props.brand} />
        </div>
        <div className="MainMenuContainer">
          <img
            className="MainMenuContainerBrand hidden-lt-lg"
            src={this.props.brand}
            onClick={this.handleOnBrandClick}
          />
          <div className="MainMenuItemContainer">{this.props.children}</div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(MainMenuContainer);
